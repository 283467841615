import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';
import benefitsImage from '../../../assets/img/pricing/new-money.png';
import feesImage from '../../../assets/img/pricing/20-prozent.png';
import example1 from '../../../assets/img/pricing/beispiel_1.jpg';
import example2 from '../../../assets/img/pricing/beispiel_2.jpg';
import DonationCalculator from '../../Pricing/DonationCalculator';

const Pricing = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  const styles = {
    exampleImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
      marginBottom: '15px'
    }
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Pricing | MY SPONSOR</title>
          <meta name="description" content="Transparent pricing and fees structure for MY SPONSOR platform - Making sports sponsoring accessible and efficient." />
          <meta property="og:title" content="Pricing | MY SPONSOR" />
          <meta property="og:description" content="Transparent pricing and fees structure for MY SPONSOR platform - Making sports sponsoring accessible and efficient." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/pricing" />
          <meta name="twitter:title" content="Pricing | MY SPONSOR" />
          <meta name="twitter:description" content="Transparent pricing and fees structure for MY SPONSOR platform - Making sports sponsoring accessible and efficient." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row pb-bottom-50">
            <div className="col-lg-12">
              <h5>{t('pricing.subtitle')}</h5>
              <h1 className="page-title">{t('pricing.title')}</h1>
            </div>
          </div>

          {/* Benefits Section */}
          <div className="row pb-bottom-80 donate-steps">
            <div className="col-lg-6 text-step">
              <div className="text-default">
                <h2 className="title">
                  {t('pricing.benefits.title')}
                </h2>
                <p className="text">{t('pricing.benefits.text')}</p>
              </div>
            </div>
            <div className="col-lg-6 image-step">
              <div className="text-default">
                <img className="smart-phone donate-step" style={{maxWidth: "80%"}} src={benefitsImage} alt="MY SPONSOR Benefits" />
              </div>
            </div>
          </div>

          {/* Fees Section */}
          <div className="row pb-bottom-60 donate-steps">
            <div className="col-lg-6 image-step">
              <div className="text-default">
                <img className="smart-phone donate-step" src={feesImage} alt="MY SPONSOR Fees" />
              </div>
            </div>
            <div className="col-lg-6 text-step">
              <div className="text-default">
                <h2 className="title">
                  {t('pricing.fees.title')}
                </h2>
                <p className="text">{t('pricing.fees.text')}</p>
              </div>
            </div>
          </div>

          {/* Calculator Section */}
          <div className="text-default pb-bottom-80">
            <DonationCalculator />
          </div>

          {/* Examples Section */}
          <div className="row pb-bottom-60">
            <div className="col-lg-12">
              <div className="text-default text-center">
                <p className="text">{t('pricing.examples.text')}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-default">
                <img style={styles.exampleImage} src={example1} alt="Example 1" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-default">
                <img style={styles.exampleImage} src={example2} alt="Example 2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Pricing;
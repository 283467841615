import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';
import fimLogo from '../../../assets/img/partners/fim-logo.png';

const Partners = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Our Partners | MY SPONSOR</title>
          <meta name="description" content="Meet our partners at MY SPONSOR - Together we make a difference in sports sponsoring and social impact." />
          <meta property="og:title" content="Our Partners | MY SPONSOR" />
          <meta property="og:description" content="Meet our partners at MY SPONSOR - Together we make a difference in sports sponsoring and social impact." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/partners" />
          <meta name="twitter:title" content="Our Partners | MY SPONSOR" />
          <meta name="twitter:description" content="Meet our partners at MY SPONSOR - Together we make a difference in sports sponsoring and social impact." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row pb-bottom-50">
            <div className="col-lg-12">
              <h5>{t('partners.subtitle')}</h5>
              <h1 className="page-title">{t('partners.fim.title')}</h1>
            </div>
          </div>
          <div className="row pb-bottom-80 video-row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="text-default">
                <iframe
                  width="100%"
                  height="380"
                  src={"https://player.vimeo.com/video/1031950165?h=3f73735a0f&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&quality=4k"}
                  title="Football is More"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
          <div className="row pb-bottom-80 donate-steps">
            <div className="col-lg-6 text-step">
              <div className="text-default">
                <p className="text">{t('partners.fim.description')}</p>
                <div className="download-buttons">
                  <div className="group-btn">
                    <a href="/football-is-more" className="btn" style={{paddingLeft: 0, fontSize: 20, textAlign: "center"}}>
                      {t('partners.fim.buttons.primary')}
                    </a>
                    <a
                      href="https://www.footballismore.org/"
                      className="btn"
                      style={{paddingLeft: 0, fontSize: 20, textAlign: "center"}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('partners.fim.buttons.secondary')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 image-step">
              <div className="text-default">
                <img className="partner-logo" style={{maxWidth: "80%"}} src={fimLogo} alt="Football is More Logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Partners;
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import UserRegister from '../containers/Auth/UserRegister';
import ClubRegister from '../containers/Auth/ClubRegister';
import Login from '../containers/Auth/Login';
import Home from '../containers/Home';
import ClubProfile from '../containers/Profile/ClubProfile';
import UserProfile from '../containers/Profile/UserProfile';
import ClubPublicProfile from '../containers/Profile/ClubPublicProfile';
import ClubsListView from '../containers/Club/ClubsListView';
import EditClubProfile from '../containers/Profile/EditClubProfile';
import Checkout from '../components/Checkout';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import ResetPassword from '../containers/Auth/ResetPassword';
import PrivateRoute from './privateRoute';

// footer section linked pages
import Feedback from '../components/Pages/Contact/Feedback';
import PressAndMedia from '../components/Pages/Others/PressAndMedia';
import Jobs from '../components/Pages/Others/Jobs';
import Contact from '../components/Pages/Contact';

import FAQSAthlete from '../components/Pages/FAQs/Athlete';
import SponsorAthletes from '../components/Pages/Others/SponsorAthletes';
import SponsorAssociations from '../components/Pages/Others/SponsorAssociations';
import SponsorTeams from '../components/Pages/Others/SponsorTeams';

import FAQSponsors from '../components/Pages/FAQs/Sponsors';
import DonateApp from '../components/Pages/Others/DonateApp';
import DonationReceipt from '../components/Pages/Others/DonationReceipt';
import HowItWork from '../components/Pages/Others/HowItWork';
import Pricing from '../components/Pages/Others/Pricing';

import Privacy from '../components/Pages/Others/Privacy';
import Conditions from '../components/Pages/Others/Conditions';
import AboutUs from '../components/Pages/Others/AboutUs';
import Partners from '../components/Pages/Others/Partners';

import TwintSuccess from '../components/Checkout/TwintPaymentSuccess';
import TwintError from '../components/Checkout/TwintPaymentError';
import TwintCancel from '../components/Checkout/TwintPaymentCancelled';
import RegistrationConfirmation from '../components/Pages/Club/RegistrationConfirmation';

// job Pages
import JobAgent from '../components/Pages/Others/JobAgent';
import JobDeveloper from '../components/Pages/Others/JobDeveloper';
import StripePayment from '../components/IosPayment/StripePayment';

const Routes = () => {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/sponsoring-einzelsportler" exact component={SponsorAthletes} />
        <Route path="/sponsoring-vereine" exact component={SponsorAssociations} />
        <Route path="/sponsoring-teams" exact component={SponsorTeams} />
        <Route path="/faq-sportler" exact component={FAQSAthlete} />

        <Route path="/wie-funktionierts" exact component={HowItWork} />
        <Route path="/spenden-app" exact component={DonateApp} />
        <Route path="/spendenbescheinigung" exact component={DonationReceipt} />
        <Route path="/faq-sponsoren" exact component={FAQSponsors} />
        <Route path="/kosten" exact component={Pricing} />

        <Route path="/feedback" exact component={Feedback} />
        <Route path="/presse-medien" exact component={PressAndMedia} />
        <Route path="/jobs" exact component={Jobs} />
        <Route path="/contact" exact component={Contact} />

        <Route path="/agb" exact component={Conditions} />
        <Route path="/datenschutz" exact component={Privacy} />
        <Route path="/ueber-uns" exact component={AboutUs} />
        <Route path="/partners" exact component={Partners} />

        <Route path="/jobs/agent-werden" exact component={JobAgent} />
        <Route path="/jobs/react-developer" exact component={JobDeveloper} />

        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/clubs" exact component={ClubsListView} />
        <Route path="/register/user" component={UserRegister} />
        <PrivateRoute path="/register/club" component={ClubRegister} />
        <Route path="/login" component={Login} />
        <Route path="/:shortName" exact component={ClubPublicProfile} />
        <Route path="/payment-stripe/:productId/:clubId/:userId/:authToken/:amount" component={StripePayment} />
        <PrivateRoute path="/donation/checkout" exact component={Checkout} />
        <PrivateRoute path="/profile/club" exact component={ClubProfile} />
        <PrivateRoute path="/edit/profile/club" exact component={EditClubProfile} />
        <PrivateRoute path="/profile/user" component={UserProfile} />
        <Route path="/payment/twint-success" exact component={TwintSuccess} />
        <Route path="/payment/twint-error" exact component={TwintError} />
        <Route path="/payment/twint-cancelled" exact component={TwintCancel} />
        <PrivateRoute
          path="/club/registration-success"
          exact
          component={RegistrationConfirmation}
        />
      </Switch>
    </Router>
  );
};

export default Routes;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logo from '../../assets/img/logo.png';
import { userLogOut } from '../../actions/userActions';

const dropdownStyles = {
  navDropdown: {
    position: 'relative'
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    background: 'white',
    minWidth: '200px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    zIndex: 1
  },
  dropdownItem: {
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
    ':hover': {
      background: '#f1f1f1'
    }
  },
  mobileDropdownContent: {
    position: 'static',
    background: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  mobileDropdownItem: {
    backgroundColor: 'white',
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    margin: '4px 0',
    borderRadius: '4px'
  }
};

const NavDropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 992;

  // In the NavDropdown component, change the mobile return to:
  if (isMobile) {
    return (
      <div  style={{
          width: '100%'
        }}>
        <div onClick={() => setIsOpen(!isOpen)} className="nav-link">
          {title}
        </div>
        <div style={{
          maxHeight: isOpen ? '500px' : '0',
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '8px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
          }}>
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                style: {
                  color: 'black',
                  textDecoration: 'none',
                  padding: '8px 0',
                  borderBottom: '1px solid #eee'
                }
              })
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={dropdownStyles.navDropdown}
      className="nav-dropdown"
      onMouseEnter={(e) => e.currentTarget.querySelector('.dropdown-content').style.display = 'block'}
      onMouseLeave={(e) => e.currentTarget.querySelector('.dropdown-content').style.display = 'none'}
    >
      <div className="nav-link">{title}</div>
      <div className="dropdown-content" style={dropdownStyles.dropdownContent}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            style: dropdownStyles.dropdownItem
          })
        )}
      </div>
    </div>
  );
};

const Appbar = (props) => {
  const { userLoggedIn, authUserData } = props;
  const [clubId, setClubId] = useState(null);
  const [language, setLanguage] = useState('du');
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const handleChange = (e) => {
    localStorage.setItem('language', e.target.value);
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const selectedLanguage = localStorage.getItem('language') || 'du';
  useEffect(() => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleSingOut = () => {
    const { userLogOut: logout, history } = props;
    logout();
    history.push('/login');
  };

  useEffect(() => {
    if (authUserData && authUserData.clubId) {
      setClubId(authUserData.clubId);
    }
  }, [authUserData]);

  const goToLogin = (e) => {
    const { history, location } = props;
    e.preventDefault();
    history.push({
      pathname: '/login',
      state: { from: location },
    });
  };

  const handleClick = () => {
    setIsActive(current => !current);
  };

  return (
    <header className="header-section" id="header-section">
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 d-flex justify-content-start reunir-content-center">
              <div className="header-left">
                <ul>
                  <li className="header-left-list">
                    <p className="header-left-text">
                      <a href="/contact">
                        <span className="header-left-icon">
                          <i className="icofont-headphone-alt" />
                        </span>
                        {t('header_Support')}
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-end reunir-content-center">
              <div className="header-right">
                <ul>
                  <li className="header-right-list">
                    <div className="dropdown show header-dropdown">
                      <span className="header-left-icon">
                        <i className="icofont-web" />
                      </span>
                      <select
                        value={language}
                        onChange={handleChange}
                        className="selectpicker"
                        name="languages"
                        tabIndex="-98"
                      >
                        <option value="en">English</option>
                        <option value="du">Deutsch</option>
                        <option value="fr">Française</option>
                        <option value="it">Italiano</option>
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav id="navbar" className="navbar navbar-expand-lg navbar-light reunir-navbar">
        <div className="container">
          <div className="logo-section">
            <a className="logo-title navbar-brand" href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleClick}
          >
            <i className="icofont-navigation-menu" />
          </button>
          <div
            className={isActive
              ? 'collapse navbar-collapse nav-main justify-content-end show'
              : 'collapse navbar-collapse nav-main justify-content-end'
            }
            id="navbarNav"
          >
            <ul className="navbar-nav" id="primary-menu">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  {t('header_home')}
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/clubs">
                  {t('header_clubs')}
                </a>
              </li>

              <li className="nav-item">
                <NavDropdown title={t('header_contact_title')}>
                  <a href="/wie-funktionierts">{t('header_contact')}</a>
                  <a href="/kosten">{t('header_pricing')}</a>
                </NavDropdown>
              </li>

              <li className="nav-item">
                <NavDropdown title={t('header_about')}>
                  <a href="/partners">{t('header_partners')}</a>
                  <a href="/ueber-uns">{t('header_about')}</a>
                </NavDropdown>
              </li>

              <li className="nav-item login-nav">
                {userLoggedIn && authUserData ? (
                  <NavDropdown
                    title={
                      <span className="d-flex align-items-center justify-content-center">
                        <AccountCircleIcon className="mr-1" /> {authUserData.firstName}
                      </span>
                    }
                  >
                    <a href="/profile/user">{t('header_profile')}</a>
                    {clubId ? (
                      <a href="/profile/club">{t('header_toclub')}</a>
                    ) : (
                      <a href="/register/club">{t('header_createclub')}</a>
                    )}
                    <a href="#" onClick={handleSingOut}>{t('header_signout')}</a>
                  </NavDropdown>
                ) : (
                  <a className="nav-link" href="/login" onClick={goToLogin}>
                    <span className="d-flex align-items-center">
                      <AccountCircleIcon /> {t('sign_in')}
                    </span>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

Appbar.propTypes = {
  userLoggedIn: PropTypes.string,
  userLogOut: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  authUserData: PropTypes.shape({
    firstName: PropTypes.string,
    clubId: PropTypes.number,
  }),
  location: PropTypes.shape({}),
};

Appbar.defaultProps = {
  userLoggedIn: null,
  userLogOut: null,
  authUserData: {},
  location: {},
};

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  authUserData: state.user.authUserData,
});

const mapDispatchToProps = {
  userLogOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Appbar));
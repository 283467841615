import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import bgImg from '../../assets/img/pricing/kreuze.png';
import bgImg2 from '../../assets/img/pricing/punkte.png';

const DonationCalculator = () => {
  const [inputValue, setInputValue] = useState('');
  const [calculationValue, setCalculationValue] = useState(10);
  const { t } = useTranslation();

  const calculateFee = (amount) => {
    const parsedAmount = parseFloat(amount) || 0;
    const fee = Math.min(parsedAmount * 0.2, 20);
    return fee;
  };

  const calculateEarnings = (amount) => {
    const parsedAmount = parseFloat(amount) || 0;
    const fee = calculateFee(parsedAmount);
    return parsedAmount - fee;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setCalculationValue(value || 10);
  };



  // CSS for pseudo-elements
  const pseudoStyles = `
    .calculator-card {
      position: relative;
    }

    .calculator-card::before,
    .calculator-card::after {
      content: "";
      position: absolute;
      width: 80px;
      height: 80px;
      opacity: 0.7;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
    }

    .calculator-card::before {
      top: -30px;
      right: -50px;
      height: 150px;
      width: 150px;
      background-image: url(${bgImg});
      transform: rotate(110deg);
    }

    .calculator-card::after {
      bottom: -125px;
      left: -75px;
      height: 200px;
      width: 200px;
      background-image: url(${bgImg2});
      transform: rotate(45deg);
      z-index: -1;
    }
  `;

  const styles = {
    container: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0 20px'
    },
    title: {
      fontSize: '32px',
      textAlign: 'center',
      fontWeight: 'normal',
      marginBottom: '32px'
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '12px',
      padding: '32px',
      boxShadow: '0 0px 10px rgba(0, 0, 0, 0.1)',
      position: "relative"
    },
    flexContainer: {
      display: 'flex',
      gap: '32px',
      '@media (max-width: 768px)': {
        flexDirection: 'column'
      }
    },
    inputSection: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    label: {
      display: 'block',
      color: '#666',
      fontSize: '14px',
      marginBottom: '8px'
    },
    inputWrapper: {
      position: 'relative'
    },
    input: {
      width: '100%',
      fontSize: '20px',
      padding: '8px 0',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      textAlign: "center"
    },
    inputUnderline: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: 'linear-gradient(90deg, #009933, #0099ff)'
    },
    resultsSection: {
      flex: 1,
      borderRadius: '8px',
      padding: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    resultsContent: {
      textAlign: 'center'
    },
    profitLabel: {
      color: '#666',
      fontSize: '14px',
      marginBottom: '4px'
    },
    profitAmount: {
      color: '#00cc99',
      fontSize: '48px',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      background: 'linear-gradient(90deg, #009933, #0099ff)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    currency: {
      fontSize: '14px',
      marginRight: '4px'
    },
    fees: {
      color: '#999',
      fontSize: '12px',
      marginTop: '16px',
      paddingTop: '8px',
      borderTop: '1px solid #ddd'
    }
  };



  return (
    <div style={styles.container}>
      <style>{pseudoStyles}</style>

      <div className="text-default">
        <h2 className="title" style={styles.title}>
          <div dangerouslySetInnerHTML={{ __html: t('pricing.calculator.title') }} />
        </h2>
      </div>

      <div className="calculator-card" style={styles.card}>
        <div style={styles.flexContainer}>
          <div style={styles.inputSection}>
            <label style={styles.label}>
              {t('pricing.calculator.input.label')}
            </label>
            <div style={styles.inputWrapper}>
              <input
                type="number"
                min="0"
                step="1"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={t('pricing.calculator.input.placeholder')}
                style={styles.input}
              />
              <div style={styles.inputUnderline} />
            </div>
          </div>

          <div style={styles.resultsSection}>
            <div style={styles.resultsContent}>
              <div style={styles.profitLabel}>
                {t('pricing.calculator.result.earnings')}
              </div>
              <div style={styles.profitAmount}>
                <span style={styles.currency}>CHF</span>
                {calculateEarnings(calculationValue).toFixed(2)}
              </div>
              <div style={styles.fees}>
                {t('pricing.calculator.result.fee')}: CHF {calculateFee(calculationValue).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCalculator;